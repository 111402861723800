.dialog-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
}

.dialog {
    --dialog-primary: #e57373;
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    width: clamp(0px, 90vw, 400px);
    padding: 16px;
    margin: 0;
    background-color: #fff;
    transform: translate3d(-50%, -50%, 0);
    box-sizing: border-box;
    /*border: none;*/
    border-color: #e57373;
    border-radius: 8px;
    line-height: 1.5;
}

.dialog__title {
    margin: 0 0 0.5rem 0;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
}

.dialog__description {
    margin: 0.5rem 0;
    line-height: 1.3;
}

.dialog__input {
    display: block;
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.4rem 0.8rem;
    border: 2px solid #121212;
    border-radius: 0.3rem;
    outline: 0;
    font-size: 1.1rem;
    box-sizing: border-box;
}

.dialog__input:focus {
    border-color: var(--dialog-primary);
}

.dialog__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.dialog__button {
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.dialog__button--confirm {
    border: 1px solid var(--dialog-primary);
    background-color: var(--dialog-primary);
}

.dialog__button--cancel {
    border: 1px solid #e1e1e1;
    background-color: #fff;
    color: var(--dialog-primary);
}

@media (prefers-color-scheme: dark) {
    .dialog {
        --dialog-primary: #ffcdd2;
        background-color: #121212;
        color: #f1f1f1;
    }

    .dialog__input {
        background-color: #121212;
        color: #f1f1f1;
        border-color: #f1f1f1;
    }

    .dialog__button--confirm {
        color: #121212;
    }

    .dialog__button--cancel {
        border: 1px solid #4f4f4f;
        background-color: #121212;
        color: var(--dialog-primary);
    }
}