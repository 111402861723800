

/* legend  */
.legend {
    background: #fff;
    padding: 8px;
    border-radius: 5px;
}

.legend .row {
    display: flex;
    line-height: 30px;
}

.legend i {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.description {
    width: 250px;
    font-size: 16px;
    color: #333;
    padding: 10px 14px;
    background-color: hsla(0, 0%, 100%, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid grey;
}

/* home  */
.back-to-home {
    width: 34px;
    height: 34px;
    z-index: 999;
    cursor: pointer;
    padding: 5px;
    display: none;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 65%);
}

.show-button-home .back-to-home {
    display: block;
    margin-left: 10px;
}

/* location  */
.locateButton {
    width: 34px;
    height: 34px;
    z-index: 999;
    cursor: pointer;
    padding: 5px;
    display: block;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 65%);
}

.locateActive {
    fill: red;
}

.locatedAnimation {
    width: 17px;
    height: 17px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: #2a93ee;
    animation: borderPulse 2s infinite;
}

@keyframes borderPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}